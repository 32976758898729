import { createContext, useContext, useState, useEffect, useRef } from "react";
import { ROUTES } from "../api/config";
import { arrayBufferToBase64 } from "../utils/Util";
import { useDispatch } from "react-redux";
import {
  incrementSocketOnMsgCount,
  setActiveMeetingDetails,
  setMeetingDetails,
} from "../redux/reducers/appSlice";
import useUserInteraction from "../hooks/useUserInteraction";
import { post } from "../api";
import useRetellApi from "../hooks/useRetellApi";

type MiliStates =
  | "yet-to-start"
  | "listening"
  | "paused"
  | "generating-summary"
  | "summary-generated";

const SocketContext = createContext<any>({});

export const useSocket = () => useContext(SocketContext);

export const SocketProvider = ({ children }) => {
  const [isConnected, setIsConnected] = useState(false);
  const [activeMeetingId, setActiveMeetingId] = useState(null);
  const socketRef = useRef<WebSocket | null>(null);
  const mediaRecorderRef = useRef<any | null>(null);
  const dispatch = useDispatch();
  const [miliState, setMiliState] = useState<MiliStates>("yet-to-start");
  const isRecordingRef = useRef(false);
  const isAiAdvisorEnabledRef = useRef(false);
  const advisorTypeRef = useRef(null);

  const callInteractionApi = async (eventType) => {
    if (!socketRef.current) return;
    try {
      const res: any = await post(ROUTES.USER_INTERACTION(activeMeetingId), {
        interaction_type: eventType,
      });
      console.log("res", res);
    } catch (err) {
      console.log(err);
    }
  };

  const { isScreenVisible, lastInteractionTime } = useUserInteraction({
    callback: callInteractionApi,
  });

  //RetellAI
  const handleRetellTranscript = (transcript) => {
    if (socketRef.current) {
      socketRef.current.send(
        JSON.stringify({
          type: "TRANSCRIPT",
          transcript_data: {
            content: transcript.content,
            role: transcript.role,
          },
        })
      );
    }
  };

  const { startConversation, stopConversation, isRetellAiSpeaking } =
    useRetellApi(handleRetellTranscript);

  const startRetell = () => {
    if (isAiAdvisorEnabledRef.current) {
      startConversation(activeMeetingId, advisorTypeRef.current);
    }
  };

  useEffect(() => {
    if (!activeMeetingId) {
      dispatch(setActiveMeetingDetails(null));
    }
  }, [activeMeetingId]);

  const connect = (id, advisorType, isAiAdvisorEnabled) => {
    isAiAdvisorEnabledRef.current = isAiAdvisorEnabled;
    advisorTypeRef.current = advisorType;
    setActiveMeetingId(id);
    const url = ROUTES.WS_SOCKET(id);
    console.log(url, "url");
    if (
      !socketRef.current ||
      socketRef.current.readyState === WebSocket.CLOSED
    ) {
      socketRef.current = new WebSocket(url);

      socketRef.current.onopen = () => {
        setIsConnected(true);
        console.log("WebSocket connected");

        // if (isAiAdvisorEnabledRef.current) {
        //   startConversation(id, advisorType);
        // }
        startRecording();
      };

      socketRef.current.onclose = (event) => {
        setIsConnected(false);
        setActiveMeetingId(null);
        console.log("WebSocket disconnected", event);

        stopRecording();
      };

      socketRef.current.onerror = (error) => {
        console.error("WebSocket connection error: ", error);
      };

      socketRef.current.onmessage = (event) => {
        const data = JSON.parse(event.data);
        console.log("Received message: ", data);

        if (data.type === "STATUS") {
          setMiliState(data.status);
          dispatch(setMeetingDetails({ mili_status: data.status }));
          return;
        }

        if (data.type === "NOTES") {
          dispatch(incrementSocketOnMsgCount());
          dispatch(setMeetingDetails({ notes: data.notes }));
          dispatch(setActiveMeetingDetails({ notes: data.notes }));
        }

        if (data.type === "FOLLOW_UP_EMAIL") {
          dispatch(incrementSocketOnMsgCount());
          dispatch(
            setMeetingDetails({ follow_up_email: data.follow_up_email })
          );
          dispatch(
            setActiveMeetingDetails({ follow_up_email: data.follow_up_email })
          );
        }
      };
    } else {
      disconnect();
      connect(id, advisorType, isAiAdvisorEnabled);
    }
  };

  const disconnect = () => {
    if (socketRef.current) {
      socketRef.current.close();
      socketRef.current = null;
      console.log("WebSocket disconnected");
    }
  };

  //STOP|PAUSE|UNPAUSE
  const pauseStop = (type) => {
    if (socketRef.current) {
      sendCommand(type);

      if (type === "STOP") {
        // setActiveMeetingId(null);
        stopRecording();
        stopConversation();
      }
      if (type === "PAUSE") {
        stopRecording();
      }
      if (type === "UNPAUSE") {
        startRecording();
      }
      console.log("WebSocket pauseStop");
    }
  };

  async function startRecording() {
    isRecordingRef.current = true;
    sendCommand("START");

    if (isAiAdvisorEnabledRef.current) return;

    const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
    mediaRecorderRef.current = new MediaRecorder(stream);

    mediaRecorderRef.current.ondataavailable = async function (e) {
      const audioBlob = await e.data.arrayBuffer();
      const audioBase64 = arrayBufferToBase64(audioBlob);
      socketRef.current.send(
        JSON.stringify({ type: "AUDIO", audio_data: audioBase64 })
      );
    };

    mediaRecorderRef.current.start(1000); // Send audio data every 1000ms
    console.log("Sent audio data");
  }

  function stopRecording() {
    isRecordingRef.current = false;

    if (isAiAdvisorEnabledRef.current) return;

    if (
      mediaRecorderRef.current &&
      mediaRecorderRef.current.state !== "inactive"
    ) {
      mediaRecorderRef.current.stop(); // Stops the recording
      mediaRecorderRef.current = null;
      console.log("Stopped recording.");
    }
  }

  function sendCommand(command) {
    if (!socketRef.current) return;
    socketRef.current.send(
      JSON.stringify({ type: "COMMAND", command: command })
    );
    console.log("Sent command");
  }

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        setActiveMeetingId(null);
        socketRef.current.close();
      }
    };
  }, []);

  return (
    <SocketContext.Provider
      value={{
        connect,
        disconnect,
        startRecording,
        sendCommand,
        isConnected,
        activeMeetingId,
        pauseStop,
        miliState,
        setMiliState,
        startRetell,
        isRetellAiSpeaking,
      }}
    >
      {children}
    </SocketContext.Provider>
  );
};
