import { lazy, useEffect } from "react";
import {
  unstable_HistoryRouter as HistoryRouter,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import history from "./history";
import Layout from "./layout";
import PrivateRoute from "./privateRoute";
import { RootState } from "../redux/store";
import { useSelector } from "react-redux";

const Login = lazy(
  /* webpackChunkName: 'Login' */ () => import("../pages/login")
);
const UpcomingMeetings = lazy(
  /* webpackChunkName: 'UpcomingMeetings' */ () =>
    import("../pages/upcomingMeetings")
);
const AllClients = lazy(
  /* webpackChunkName: 'AllClients' */ () => import("../pages/allClients")
);
const ClientAllMeetings = lazy(
  /* webpackChunkName: 'ClientAllMeetings' */ () =>
    import("../pages/clientAllMeetings")
);
const MeetingDetails = lazy(
  /* webpackChunkName: 'MeetingDetails' */ () =>
    import("../pages/meetingDetails")
);

const Settings = lazy(
  /* webpackChunkName: 'settings' */ () => import("../pages/settings")
);

const Router = () => {
  const userDetails = useSelector((state: RootState) => state.app.userDetails);

  useEffect(() => {
    if (userDetails?.onboarded && !localStorage.getItem("user_onboard")) {
      localStorage.setItem("user_onboard", "true");
      history.push("/upcoming-meetings");
    }
  }, [userDetails]);

  return (
    //@ts-ignore
    <HistoryRouter history={history}>
      <Routes>
        <Route path="/login" element={<Login />} />
        <Route
          path="/upcoming-meetings"
          element={
            <PrivateRoute
              Component={
                <Layout>
                  <UpcomingMeetings />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/past-meetings"
          element={
            <Layout>
              <div />
            </Layout>
          }
        />
        <Route
          path="/all-clients"
          element={
            <PrivateRoute
              Component={
                <Layout>
                  <AllClients />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/client/:clientId"
          element={
            <PrivateRoute
              Component={
                <Layout>
                  <ClientAllMeetings />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/client/:clientId/meeting-details/:meetingId"
          element={
            <PrivateRoute
              Component={
                <Layout>
                  <MeetingDetails />
                </Layout>
              }
            />
          }
        />
        <Route
          path="/customize-notes"
          element={
            <Layout>
              <div />
            </Layout>
          }
        />
        <Route
          path="/settings"
          element={
            <Layout>
              <Settings />
            </Layout>
          }
        />
        <Route
          path="*"
          element={<Navigate to="/upcoming-meetings" replace />}
        />
      </Routes>
    </HistoryRouter>
  );
};

export default Router;
