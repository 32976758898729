import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  fetchUserProfile,
  fetchClientDetails,
  fetchClientList,
  fetchMeetingList,
  fetchClientMeetingList,
  fetchMeetingDetails,
  updateMeetingDetails,
  createMeeting,
  fetchContactList,
  fetchMeetingTypeList,
} from "../actions/appActions";
import { toastEmitter } from "../../components/toast/toastManager";
import history from "../../router/history";

interface AppState {
  token: string | null;
  clientList: any;
  clientDetails: any;
  userDetails: any;
  meetingList: any;
  clientMeetingList: any;
  meetingDetails: any;
  socketOnMsgCount: number;
  activeMetingDetails: any;
  addMeetingToggle: boolean;
  contacts: any;
  meetingTypes: any;
}

const initialState: AppState = {
  token: null,
  userDetails: null,
  clientList: {
    data: [],
    isLoading: false,
    error: null,
  },
  clientDetails: {
    data: null,
    isLoading: false,
    error: null,
  },
  meetingList: {
    upcoming: [],
    current: [],
    isLoading: true,
    error: null,
  },
  clientMeetingList: {
    data: [],
    isLoading: false,
    error: null,
  },
  meetingDetails: {
    data: null,
    isLoading: false,
    error: null,
  },
  socketOnMsgCount: 0,
  activeMetingDetails: null,
  addMeetingToggle: false,
  contacts: [],
  meetingTypes: [],
};

function addUserProfileCases(builder) {
  builder
    .addCase(fetchUserProfile.pending, (state) => {
      // state.userDetails.isLoading = true;
      // state.userDetails.error = null;
    })
    .addCase(fetchUserProfile.fulfilled, (state, action) => {
      // state.userDetails.isLoading = false;
      state.userDetails = action.payload;
    })
    .addCase(fetchUserProfile.rejected, (state, action) => {
      // state.userDetails.isLoading = false;
      // state.userDetails.error = action.error.message;
    });
}

function addClientListCases(builder) {
  builder
    .addCase(fetchClientList.pending, (state) => {
      state.clientList.isLoading = true;
      state.clientList.error = null;
    })
    .addCase(fetchClientList.fulfilled, (state, action: any) => {
      state.clientList.isLoading = false;
      state.clientList.error = null;
      state.clientList.data = action.payload.clients;
    })
    .addCase(fetchClientList.rejected, (state, action: any) => {
      state.clientList.isLoading = false;
      state.clientList.error =
        action.error.message || "Error occurred while fetching client list.";
    });
}

function addClientDetailsCases(builder) {
  builder
    .addCase(fetchClientDetails.pending, (state) => {
      state.clientDetails.isLoading = true;
      state.clientDetails.error = null;
    })
    .addCase(fetchClientDetails.fulfilled, (state, action) => {
      state.clientDetails.isLoading = false;
      state.clientDetails.data = action.payload;
    })
    .addCase(fetchClientDetails.rejected, (state, action) => {
      state.clientDetails.isLoading = false;
      state.clientDetails.error = action.error.message;
    });
}

function addMeetingListCases(builder) {
  builder
    .addCase(fetchMeetingList.pending, (state) => {
      state.meetingList.isLoading = true;
      state.meetingList.error = null;
    })
    .addCase(fetchMeetingList.fulfilled, (state, action) => {
      state.meetingList.isLoading = false;
      state.meetingList.current = action.payload.filter(
        (i) => i.meeting_status === "In-Progress"
      );
      state.meetingList.upcoming = action.payload.filter(
        (i) => i.meeting_status === "Planned"
      );
    })
    .addCase(fetchMeetingList.rejected, (state, action) => {
      state.meetingList.isLoading = false;
      state.meetingList.error = action.error.message;
    });
}

function addClientMeetingListCases(builder) {
  builder
    .addCase(fetchClientMeetingList.pending, (state) => {
      state.clientMeetingList.isLoading = true;
      state.clientMeetingList.error = null;
      state.clientMeetingList.data = [];
    })
    .addCase(fetchClientMeetingList.fulfilled, (state, action) => {
      state.clientMeetingList.isLoading = false;
      state.clientMeetingList.data = action.payload;
    })
    .addCase(fetchClientMeetingList.rejected, (state, action) => {
      state.clientMeetingList.isLoading = false;
      state.clientMeetingList.error = action.error.message;
    });
}

function addMeetingDetailsCases(builder) {
  builder
    .addCase(fetchMeetingDetails.pending, (state, action) => {
      if (!action.meta.arg.silentRefresh) {
        state.meetingDetails.isLoading = true;
      }
      state.meetingDetails.error = null;
      // state.meetingDetails.data = null;
    })
    .addCase(fetchMeetingDetails.fulfilled, (state, action) => {
      state.meetingDetails.isLoading = false;
      if (state.activeMetingDetails?.id === action.payload.id) {
        state.meetingDetails.data = state.activeMetingDetails;
      } else {
        state.meetingDetails.data = action.payload;
      }
      if (action.meta.arg.silentRefresh) {
        state.socketOnMsgCount = state.socketOnMsgCount + 1;
      }
    })
    .addCase(fetchMeetingDetails.rejected, (state, action) => {
      state.meetingDetails.isLoading = false;
      state.meetingDetails.error = action.error.message;
    });
}

function addUpdateMeetingDetailsCases(builder) {
  builder
    .addCase(updateMeetingDetails.pending, (state) => {})
    .addCase(updateMeetingDetails.fulfilled, (state, action) => {
      toastEmitter.show({
        message: "Meeting updated successfully",
        severity: "success",
      });
    })
    .addCase(updateMeetingDetails.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}

function addCreateMeetingCases(builder) {
  builder
    .addCase(createMeeting.pending, (state) => {})
    .addCase(createMeeting.fulfilled, (state, action) => {
      toastEmitter.show({
        message: "Meeting created successfully",
        severity: "success",
      });
      const data = action.payload;
      if (data) {
        history.push(`/client/${data.client_id}/meeting-details/${data.id}`);
      }
    })
    .addCase(createMeeting.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}
function addContactListCases(builder) {
  builder
    .addCase(fetchContactList.pending, (state) => {})
    .addCase(fetchContactList.fulfilled, (state, action) => {
      state.contacts = action.payload.clients;
    })
    .addCase(fetchContactList.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}
function addMeetingTypeListCases(builder) {
  builder
    .addCase(fetchMeetingTypeList.pending, (state) => {})
    .addCase(fetchMeetingTypeList.fulfilled, (state, action) => {
      state.meetingTypes = action.payload.meeting_types;
    })
    .addCase(fetchMeetingTypeList.rejected, (state, action) => {
      toastEmitter.show({ message: "Something went wrong", severity: "error" });
    });
}

const appSlice = createSlice({
  name: "app",
  initialState,
  reducers: {
    setToken(state, action) {
      state.token = action.payload;
      if (action.payload) {
        localStorage.setItem("token", action.payload); // Save token to localStorage
      } else {
        localStorage.clear();
      }
    },
    incrementSocketOnMsgCount(state) {
      state.socketOnMsgCount = state.socketOnMsgCount + 1;
    },
    setMeetingDetails(state, action) {
      state.meetingDetails.data = {
        ...state.meetingDetails.data,
        ...action.payload,
      };
    },
    setActiveMeetingDetails(state, action) {
      state.activeMetingDetails = action.payload
        ? {
            ...state.meetingDetails.data,
            ...action.payload,
          }
        : null;
    },
    setAddMeetingToggle(state, action) {
      state.addMeetingToggle = action.payload;
    },
  },
  extraReducers: (builder) => {
    addUserProfileCases(builder);
    addClientListCases(builder);
    addClientDetailsCases(builder);
    addMeetingListCases(builder);
    addClientMeetingListCases(builder);
    addMeetingDetailsCases(builder);
    addUpdateMeetingDetailsCases(builder);
    addCreateMeetingCases(builder);
    addContactListCases(builder);
    addMeetingTypeListCases(builder);
  },
});

export const {
  setToken,
  setMeetingDetails,
  incrementSocketOnMsgCount,
  setActiveMeetingDetails,
  setAddMeetingToggle,
} = appSlice.actions;

export const selectToken = (state: RootState) => state.app.token;

export default appSlice.reducer;
