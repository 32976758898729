import { useTheme } from "@mui/material/styles";
import useMediaQuery from "@mui/material/useMediaQuery";

export function calculateAge(dob: string): number {
  const today = new Date();
  const birthDate = new Date(dob);

  let age = today.getFullYear() - birthDate.getFullYear();
  const monthDiff = today.getMonth() - birthDate.getMonth();

  // If birth date month is greater than current month or
  // if birth date month is equal to current month but birth date day is greater than current day,
  // then decrement age by 1
  if (
    monthDiff < 0 ||
    (monthDiff === 0 && today.getDate() < birthDate.getDate())
  ) {
    age--;
  }

  return age;
}

export function formatDateTime(dateTimeString: string): {
  date: string;
  time: string;
} {
  if (!dateTimeString) return { date: "", time: "" };
  const date = new Date(dateTimeString);
  const dateOptions: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric",
  };
  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
  };

  const formattedDate = date.toLocaleDateString("en-US", dateOptions);
  const formattedTime = date.toLocaleTimeString("en-US", timeOptions);

  return { date: formattedDate, time: formattedTime };
}

export function arrayBufferToBase64(buffer) {
  var binary = "";
  var bytes = new Uint8Array(buffer);
  var len = bytes.byteLength;
  for (var i = 0; i < len; i++) {
    binary += String.fromCharCode(bytes[i]);
  }
  return window.btoa(binary);
}
export const isExtension = window.location.protocol === "chrome-extension:";
export const isSmallDevice = window.matchMedia("(max-width: 600px)").matches;
export const isMobile = isSmallDevice && !isExtension;

export const injectMicrophonePermissionIframe = () => {
  // const iframe = document.createElement("iframe");
  // iframe.setAttribute("hidden", "hidden");
  // iframe.setAttribute("id", "permissionsIFrame");
  // iframe.setAttribute("allow", "microphone");
  // iframe.src = chrome.runtime.getURL("permission/index.html");
  // document.body.appendChild(iframe);

  navigator.mediaDevices.getUserMedia({ audio: true }).catch((err) => {
    console.log(err);

    chrome.tabs.create({
      url: chrome.runtime.getURL("permission/index.html"),
      active: true,
    });
  });
};

export const validateEmail = (email: string): boolean => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validateOtp = (otp: string): boolean => {
  const otpRegex = /^\d{1,6}$/;
  return otpRegex.test(otp);
};
