import { useRef } from "react";

interface Word {
  word: string;
  start: number;
  end: number;
}

interface Transcript {
  role: string;
  content: string;
  words: Word[];
}

const getSecondLastTranscript = (
  transcripts: Transcript[]
): Transcript | null => {
  if (transcripts.length < 2) {
    return null;
  }
  return transcripts[transcripts.length - 2];
};

const useUniqueTranscript = () => {
  const lastDataLength = useRef<number>(0);

  const getSecondLastUniqueTranscript = (
    transcripts: Transcript[]
  ): Transcript | null => {
    // Check if the data length has changed

    if (transcripts.length === lastDataLength.current) {
      // If the data length is the same, return null to indicate no processing is needed
      return null;
    }

    // Update the cached length
    lastDataLength.current = transcripts.length;

    // Get the second-to-last transcript
    return getSecondLastTranscript(transcripts);
  };

  return { getSecondLastUniqueTranscript };
};

export { useUniqueTranscript, getSecondLastTranscript };
