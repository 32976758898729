import { createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../../api";
import { ROUTES } from "../../api/config";

export const fetchUserProfile = createAsyncThunk(
  "app/fetchUserProfile",
  async () => {
    const data = await get(ROUTES.USER_PROFILE);
    return data;
  }
);

export const fetchClientList = createAsyncThunk(
  "app/fetchClientList",
  async () => {
    const data = await get(ROUTES.CLIENT_LIST);
    return data;
  }
);

export const fetchClientDetails = createAsyncThunk(
  "app/fetchClientDetails",
  async (clientId: string) => {
    const data = await get(ROUTES.CLIENT_DETAILS(clientId));
    return data;
  }
);

export const fetchMeetingList = createAsyncThunk(
  "app/fetchMeetingList",
  async () => {
    const data = await get(ROUTES.MEETING_LIST);
    return data;
  }
);

export const fetchClientMeetingList = createAsyncThunk(
  "app/fetchClientMeetingList",
  async (clientId: string) => {
    const data = await get(ROUTES.CLIENT_MEETING_LIST(clientId));
    return data;
  }
);

export const fetchMeetingDetails = createAsyncThunk(
  "app/fetchMeetingDetails",
  async ({ meetingId }: { meetingId: string; silentRefresh?: boolean }) => {
    const data = await get(ROUTES.MEETING_DETAILS(meetingId));
    return data;
  }
);

export const updateMeetingDetails = createAsyncThunk(
  "app/updateMeetingDetails",
  async (meetingId: string, { getState }: any) => {
    const state = getState();
    let payload = { ...state.app.meetingDetails.data };
    delete payload.mili_status;

    const data = await post(ROUTES.MEETING_UPDATE(meetingId), payload);
    return data;
  }
);

export const createMeeting = createAsyncThunk("app/createMeeting", async () => {
  const data = await post(ROUTES.MEETING_CREATE, {});
  return data;
});

export const fetchContactList = createAsyncThunk(
  "app/fetchContactList",
  async () => {
    const data = await get(ROUTES.CONTACT_LIST);
    return data;
  }
);

export const fetchMeetingTypeList = createAsyncThunk(
  "app/fetchMeetingTypeList",
  async () => {
    const data = await get(ROUTES.MEETING_TYPE_LIST);
    return data;
  }
);
