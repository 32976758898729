import {
  AppBar,
  Box,
  Toolbar,
  ButtonBase,
  IconButton,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import IndeterminateCheckBoxOutlinedIcon from "@mui/icons-material/IndeterminateCheckBoxOutlined";
import ColorsConst from "../../utils/ColorsConst";
import MiliLogo from "../../assets/img/mili_logo.png";
import MiliNote from "../../assets/img/mili_note_icon.png";
import { useDispatch } from "react-redux";
import { AppDispatch } from "../../redux/store";
import MenuIcon from "@mui/icons-material/Menu";
import { isExtension, isMobile, isSmallDevice } from "../../utils/Util";
import { setAddMeetingToggle } from "../../redux/reducers/appSlice";
import { useLocation } from "react-router-dom";
import EditNoteIcon from "@mui/icons-material/EditNote";

const Header = (props) => {
  const location = useLocation();
  const dispatch = useDispatch<AppDispatch>();
  const onStartTakingNotes = () => {
    dispatch(setAddMeetingToggle(true));
  };

  const onMenuIconClick = () => {
    props.setIsDrawerOpen((val) => !val);
  };

  const showNotesBtn =
    !isSmallDevice && location.pathname === "/upcoming-meetings";
  return (
    <AppBar
      sx={{
        backgroundColor: ColorsConst.DARK_SURFACE_CONTAINER,
        zIndex: 9999,
      }}
    >
      <Toolbar
        style={{ justifyContent: "space-between", alignItems: "center" }}
      >
        <Box alignItems="center" display="flex">
          {isMobile && (
            <IconButton onClick={onMenuIconClick} sx={{ mr: "6px" }}>
              <MenuIcon sx={{ color: ColorsConst.DARK_PRIMARY }} />
            </IconButton>
          )}
          <img src={MiliLogo} alt="mili_logo" height={32} />
        </Box>
        <Box display="flex" flexDirection="row" alignItems="center">
          {showNotesBtn && (
            <ButtonBase
              sx={{
                bgcolor: ColorsConst.DARK_PRIMARY_CONTAINER,
                borderRadius: 4,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                px: "20px",
                py: 1,
              }}
              onClick={onStartTakingNotes}
            >
              <EditNoteIcon
                sx={{ color: ColorsConst.DARK_ON_PRIMARY_CONTAINER }}
              />{" "}
              <Typography
                variant="subtitle2"
                sx={{ color: ColorsConst.DARK_ON_PRIMARY_CONTAINER, ml: 0.5 }}
              >
                New meeting notes
              </Typography>
            </ButtonBase>
          )}
          {/* <IconButton>
            <SearchIcon style={{ color: ColorsConst.ON_SURFACE_VARIANT }} />
          </IconButton>
          {isExtension && (
            <IconButton>
              <IndeterminateCheckBoxOutlinedIcon
                style={{ color: ColorsConst.ON_SURFACE_VARIANT }}
              />
            </IconButton>
          )} */}
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
