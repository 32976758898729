// useToast.tsx
import React, { useEffect, useState } from "react";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import { toastEmitter, ToastMessage } from "./toastManager";

export const useToast = () => {
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [severity, setSeverity] = useState<
    "success" | "info" | "warning" | "error"
  >("info");

  useEffect(() => {
    const show = ({ message, severity }: ToastMessage) => {
      setMessage(message);
      setSeverity(severity);
      setOpen(true);
    };

    toastEmitter.on("show", show);

    return () => {
      toastEmitter.off("show", show);
    };
  }, []);

  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === "clickaway") {
      return;
    }
    setOpen(false);
  };

  return (
    <Snackbar open={open} autoHideDuration={6000} onClose={handleClose}>
      <Alert onClose={handleClose} severity={severity} sx={{ width: "100%" }}>
        {message}
      </Alert>
    </Snackbar>
  );
};
