import { useEffect, useRef, useState } from "react";

interface UseUserInteractionOptions {
  callback: (eventType: string) => void;
  delay?: number; // Delay in milliseconds
}

interface UseUserInteractionReturn {
  isScreenVisible: boolean;
  lastInteractionTime: number;
}

const useUserInteraction = ({
  callback,
  delay = 60000, //1min
}: UseUserInteractionOptions): UseUserInteractionReturn => {
  const [isScreenVisible, setIsScreenVisible] = useState<boolean>(
    document.visibilityState === "visible"
  );
  const [lastInteractionTime, setLastInteractionTime] = useState<number>(0);
  const lastCallbackTimeRef = useRef<number>(0);

  useEffect(() => {
    const handleVisibilityChange = () => {
      setIsScreenVisible(document.visibilityState === "visible");
    };

    const handleInteraction = (event: Event) => {
      const currentTime = Date.now();
      const eventType = event.type;

      const hasDelayPassed = currentTime - lastCallbackTimeRef.current > delay;

      if (isScreenVisible && hasDelayPassed) {
        lastCallbackTimeRef.current = currentTime;
        setLastInteractionTime(currentTime);
        callback(eventType);
      }
    };

    const eventTypes: string[] = [
      "click",
      "mousemove",
      "keydown",
      "scroll",
      "touchstart",
      "touchmove",
    ];

    document.addEventListener("visibilitychange", handleVisibilityChange);
    eventTypes.forEach((eventType) => {
      window.addEventListener(eventType, handleInteraction);
    });

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
      eventTypes.forEach((eventType) => {
        window.removeEventListener(eventType, handleInteraction);
      });
    };
  }, [callback, delay, isScreenVisible]);

  return {
    isScreenVisible,
    lastInteractionTime,
  };
};

export default useUserInteraction;
