import { Box } from "@mui/material";
import Header from "../components/header";
import SideNav from "../components/sideNav";
import { Suspense, useState } from "react";
import { isExtension, isMobile } from "../utils/Util";

const Layout = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const ml = isExtension ? "80px" : isMobile ? 0 : "180px";

  return (
    <Box>
      <Header setIsDrawerOpen={setIsDrawerOpen} />
      <SideNav isDrawerOpen={isDrawerOpen} setIsDrawerOpen={setIsDrawerOpen} />
      <Box
        ml={ml}
        sx={{
          mt: "54px",
          "@media (min-width:600px)": {
            mt: "64px",
          },
        }}
      >
        <Suspense>{children}</Suspense>
      </Box>
    </Box>
  );
};

export default Layout;
