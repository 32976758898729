import { useEffect, useState, useCallback } from "react";
import { RetellWebClient } from "retell-client-js-sdk";
import { ROUTES } from "../api/config";
import { post } from "../api";
import { useUniqueTranscript } from "./useTranscript";

interface RegisterCallResponse {
  call_id: string;
  sample_rate: number;
  [key: string]: any;
}

interface RetellApiHook {
  startConversation: (meetingId: number, advisorType: "AI" | "HUMAN") => void;
  stopConversation: () => void;
  isRetellAiSpeaking: boolean;
}

interface Word {
  word: string;
  start: number;
  end: number;
}

interface Transcript {
  role: string;
  content: string;
  words: Word[];
}

const sdk = new RetellWebClient();

const useRetellApi = (
  onTranscript?: (transcript: Transcript) => void
): RetellApiHook => {
  const { getSecondLastUniqueTranscript } = useUniqueTranscript();
  const [speaking, setSpeaking] = useState(false);
  console.log("speaking", speaking);

  const registerCall = useCallback(async (meetingId, advisorType) => {
    try {
      const url =
        advisorType === "AI"
          ? ROUTES.REGISTER_CALL_AI_ADVISOR(meetingId)
          : ROUTES.REGISTER_CALL_AI_CLIENT(meetingId);
      const data: RegisterCallResponse = await post(url);
      console.log("registerCallResponse", data);

      return data;
    } catch (err) {
      console.log(err);
      return null;
    }
  }, []);

  const startConversation = useCallback(
    async (meetingId, advisorType) => {
      const registerCallResponse = await registerCall(meetingId, advisorType);
      if (registerCallResponse) {
        sdk.startConversation({
          callId: registerCallResponse.call_id,
          sampleRate: registerCallResponse.sample_rate,
          enableUpdate: true,
        });
      }
    },
    [registerCall, sdk]
  );

  const stopConversation = useCallback(() => {
    sdk.stopConversation();
  }, [sdk]);

  useEffect(() => {
    sdk.on("conversationStarted", () => {
      console.log("Conversation started");
    });

    sdk.on("conversationEnded", () => {
      console.log("Conversation ended");
    });

    sdk.on("error", (error: any) => {
      console.error("An error occurred:", error);
    });

    sdk.on("update", (update: any) => {
      // console.log("update", update);
      if (update.transcript?.length) {
        const lastElement = update.transcript[update.transcript.length - 1];
        setSpeaking(lastElement.role === "agent");
      }
      if (onTranscript) {
        const secondLastTranscript = getSecondLastUniqueTranscript(
          update.transcript
        );
        if (secondLastTranscript) {
          console.log("Second-to-last transcript:", secondLastTranscript);
          onTranscript(secondLastTranscript);
        }
      }
    });

    sdk.on("audio", (audio: Uint8Array) => {
      // console.log("There is audio", audio);
    });

    sdk.on("disconnect", () => {
      console.log("disconnect");
    });

    sdk.on("reconnect", () => {
      console.log("reconnect");
    });
  }, [sdk, onTranscript]);

  return {
    startConversation,
    stopConversation,
    isRetellAiSpeaking: speaking,
  };
};

export default useRetellApi;
